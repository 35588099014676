/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



@font-face {
  font-family: dm-sans;
  src: url('/assets/fonts/DMSans-Regular.ttf');
}
@font-face {
  font-family: dm-sans-36pt;
  src: url('/assets/fonts/DMSans_36pt-Bold.ttf');
}
* {
  font-family: dm-sans;
  --ion-font-family: dm-sans;
}

.portalmessageouter, .portalinfomessageouter, .portalsuccessmessageouter {
  display:flex;
  justify-content:center;
  width:100%;
  padding:30px;
  background:#f3f2f8;
  position:absolute;
  left:0;
}
.portalmessageinner {
  max-width:600px;
  color:rgb(213, 0, 0);
  font-weight:700;
}
.portalinfomessageinner {
  max-width:600px;
  color:#15112a;
  font-weight:700;
}
.portalsuccessmessageinner {
  max-width:600px;
  color:green;
  font-weight:700;
}
ion-footer .submit-btn {
  background: #efac14;
  --background: #efac14;    
  color: #265066;
  font-weight: 600;
  font-size:16px;
  text-transform: capitalize;
  margin:20px;
  border-radius:10px;
}
.yellow {
  color:yellow;
}
.alertSubmissionDetail {
  --width:600px;
  --min-width:600px;
  .alert-wrapper .alert-message {
    background-color: white;
    color:#000;
    padding:20px !important;
  }
}
.app-settings-menu {
  padding:0;
}
.imgWelcome {
  width:600px;
  margin-bottom:20px;
}
.mbe-buttons {
  ion-button {
    margin: 0 10px;
    --border-radius: 100px;
    border-radius: 100px;
  }
  .submit-btn {
    background: #efac14;
    --background: #efac14;
    color: #265066;
    font-weight: 600;
    font-size:16px;
    text-transform: capitalize;
  }
  .cancel-btn {
    background: #ccc8c8;
    --background: #cac9c9;
    color: #000;
    text-transform: capitalize;
  }
}
.mbe-toolbar {
  display:flex;
  width:100%;
  height:20px;
  padding: 0 10px;
  justify-content:space-between;
  align-items:center;

  ion-icon.home {
    zoom:1.5;
    padding-right:10px;
  }
  ion-title {
    position:unset;
  }
}

.updateAlert .alert-title {
  text-align: center;
  margin-bottom:8px;
}

.updateAlert .alert-wrapper .alert-message {
  background:#efac14;
  background-image:radial-gradient(white, #efac14);
  color:#265066;
  padding-top:20px !important;
  text-align:center;

  .alertBold {
    font-weight:600;
  } 

  
}

.updateAlert .alert-button.buttonUpdate {
  background:green;
  color:white;
  width:100%;
  
}
.updateAlert .alert-button.buttonNotNow {
  background:silver;
  color:gray;
  font-size:0.8em;
  height:30px;
}

ion-toolbar ion-icon {
  zoom:1.5;
}
ion-toolbar ion-icon.home {
  margin-right:10px;
}
ion-toolbar span {
  font-size:16px;
}
.mbetoolbardiv {
  display:flex;
  align-items: center;
  padding-left:10px;
}
div.addmode {    
  background: #ffffff; 
}
ion-datetime {
  width:100%;
}
ion-picker>.picker-wrapper {
  background-color: var(--dark-theme-color) !important;
}

ion-datetime {
  color: var(--ion-color-medium) !important;
}

ion-datetime.datetime-header {
  background: #265066;
  --background: #265066;
}
.form-content {
  background:transparent;
  --background:transparent;
  padding: 15px;

  ion-item.editmode {      
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  div.addmode {     
    padding: 5px 10px !important; 

    .item-number-border
    {
      margin-bottom: 0 !important;
      padding-left: 10px;
      background: transparent;
      --background: transparent;
    }
  }

  ion-item, div.addmode {
    padding: 0;
    --highlight-background: none !important;
    --inner-border-width: 0 0 0px 0;
    --border-width:0;
    padding: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    --min-height: 40px;
    margin-bottom: 15px;
    border-radius:6px;
    border: 1px solid #265066 !important;
    font-family: "Play", sans-serif !important;
    
    ion-label {
      font-size: 14px;
    }
    ion-icon {
      font-size: 20px;
      position: absolute;
      font-weight: bold;
      left: 0;
      padding: 10px;
      
    }
    ion-input,
    ion-select {
      font-size: 14px;
      padding-left: 10px !important;
      font-family: "Play", sans-serif !important;
    }
    ion-textarea {
      font-size: 14px;
      padding-left: 10px !important;
      font-family: "Play", sans-serif !important;
    }
    
  }
  ion-item.iconLeft {
    ion-input,
    ion-datetime, 
    ion-select {
      font-size: 14px;
      padding-left: 50px !important;
      font-family: "Play", sans-serif !important;
    }
    
  }
  ion-datetime {
    font-size: 14px;
    --min-height: 45px;
  }
  ion-list {
    padding: 0 !important;
  }
  ion-button {
    background: #efac14;
    --background: #efac14;
    margin-top: 20px;
    border-radius: 100px;
    --border-radius: 100px;
    font-size: 14px;
  }
  
}
.speciality-form-content {
  margin-bottom: 30px;
  padding: 20px;

  .patientFieldTitle  {
    margin:0 !important;
    font-size:16px;
    font-family:"Play", sans-serif;
    letter-spacing:0.5px;
    height:50px;
    display: flex;
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;    
  }

  ion-item.patientFieldTitle {      
    padding-left:0;
    padding-inline-start:0;
  }
  h5.patientFieldTitle {      
    padding-left:20px;
    padding-inline-start:20px;
  }
  ion-item.mbe {
    border: 0;
    padding: 0;
    --inner-border-width: 0 0 0px 0;
    padding: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    --min-height: 40px;
    margin-bottom: 15px;
    ion-label {
      font-size: 14px;
    }
    ion-icon {
      font-size: 20px;
      position: absolute;
      font-weight: bold;
      left: 0;
      padding: 10px;
    }
    ion-select,
    ion-input,
    ion-datetime {
      width: 100%;
      font-size: 14px;
      --padding-start: 50px !important;
    }
    ion-datetime {
      padding-left: 50px !important;
    }
    ion-textarea {
      font-size: 14px;
      --padding-start: 10px !important;
    }
    .select-disabled{
      opacity: 1;
      
    }
  }
  .item-number ion-item {
    width: 100%;
    float: left;
    border: none;
    ion-label {
      white-space: normal;
    }
    
  }
  ion-datetime {
    font-size: 14px;
  }
  ion-toggle {
    --background: #ccc;
    --background-checked: rgba(239, 172, 19, 0.4);
    height: 24px;
    --handle-background: #333;
    --handle-background-checked: #efac14;
  }
  ion-radio {
    --color-checked: #efac14;
    margin-left: 10px;
    margin-right: 10px;
    --size: 20px;
    --ion-color-base: #efac14 !important;
  }
  .doc {
    img {
      margin: auto;
    }
  }
  .amountMask {
    background:transparent;
    border:0;
    padding-left:50px;
    padding-right:10px;
    width:100%;
    font-size:14px;
  }
}
ion-header {


  .modal-header{
    padding-left: 0px;
    height: 50px;
    font: bold;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ion-toolbar {
    --background: #15112a;
    background: #15112a;
    color: #fff;
    padding: 5px !important;  
    margin: 0 !important;  

    ion-title {
      padding-inline:unset;
      font-size:16px;
      font-weight:600;
    }
    .mbeAddButton {
      background: #efac14;
      padding: 5px;
      --padding-start: 0;
      width: 30px;
      --padding-end: 0px;
      border-radius: 100px;
      --color: #265066;
      color: #265066;
      height: 30px;
    }

    ion-buttons {
      ion-icon {
        padding-right: 20px;
        font-size: 24px;
      }
    }
    ion-back-button {
      color: #fff;
    }
    .right-header-icons {
      text-align: right;
      ion-icon {
        font-size: 20px;
        margin-right: 3px;
        margin-bottom:unset;
        color: #ffffff;
        padding: 2px;
        border-radius: 100px;
        zoom:1.5;
      }
      ion-icon:last-child {
        margin-right: 0px;
      }
    }
    ion-badge {
      background:#efac14 !important;
      color:#265066 !important;
      border:0 !important;
      border-radius:20px;
      font-size:0.8em !important;
    }
  }
}

ion-back-button {
  --padding-start: 0px;
  --padding-end: 20px;
}

.mbeAccordion {
  background:#efac14;
  --background:#efac14;
  --ripple-color: transparent;
}
.mbeAccordion::part(native) {
  background:transparent;
}
.mbeInstructions {
  color: #265066;
  background: #FFF !important;
  --background: #FFF;
  text-align:justify;
  padding-top:0;
}

ion-content {
  --background: none;
  background: #fff;
  background-image: url(/assets/img/lightbackground.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  
  .billing-name {
    background:#efac14;
    --background:#efac14 !important;
    color:#092430;
    padding:10px;
  }

  .billing-rows {
    display:flex;
    width:100%;  
    padding:5px 0;
    align-items:center;

    ion-label {
      flex:1;
      margin-bottom:0 !important;
    }
  }
  .error-message {
    color:red;
    position:relative;
    top:-12px;
  }

  .mbe-chip {
    margin:0;
    margin-bottom:20px;
    padding:20px 10px;
    background:#ffcf62;
    color: #092430;
    height:unset;

    ion-icon {
      zoom:2;
      width:25px;
      margin:0;
      margin-right:5px;   
      flex:0.2;   
    }
    ion-label {
      font-size:12px;
      margin-bottom:0 !important;
      flex:1;
    }
  }
  ion-label {
    margin-bottom: 10px !important;
    font-family: "Play", sans-serif !important;
  }
  .homeButton.boldlabel ion-label {
    margin-bottom: 10px !important;
    font-family: dm-sans-36pt !important;
  }
  .upload-icon {
    color:#efac14;
    font-size: 32px;
    width: 100%;
  }  
  .upload-btn {
    --border-radius: 100px;
    font-weight:600;
    height: 40px;
    --background: #efac14;
    margin-top: 5px;
    width: 100%;
    color: #265066;
    border-radius: 100px;
    box-shadow: none;
    --box-shadow: none;
    font-size:16px;
    text-transform: capitalize;
  }
  .mbe-form-section {
    border-top: 1px solid #a09e9e;
    border-bottom:none;
    border-right:none;
    border-left:none;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
  }

  .mbe-titles {
    font-size:18px !important;
    scale: 100%;
    transform: translateY(0) !important;
    margin-bottom: 0 !important;
  }
  .underlined {
    border-bottom:1px solid silver;
    margin-bottom:10px !important;
  }

  .logo-section {
    text-align: center;
    margin-top: 12vmin;
    .logo{
      width: 300px;
    }
  }

  .submission_comments_header {
    background: #dedede; margin: 20px -10px -20px -10px; padding-top: 15px; color: #265066; font-weight: 700;
  }
  .mbe-lookup-searchbar {
    width: 100%;
  }
  .bg-section {
    --background: #fff;
    box-shadow: 0 3px 4px 0px #000000a3;
    text-align: center;
    margin: 10px;
    border-radius: 10px;
    ion-icon {
      color: #265066;
      font-size: 38px;
      margin-top: 20px;
    }
    h4 {
      color: #265066;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 5px;
    }
    h3 {
      color: #265066;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }

  .notification-section {
    box-shadow: 0px 2px 6px #000000b5;
    padding: 15px !important;
    margin-bottom: 20px !important;
    background: #265066;
    color:white;
    border-radius:6px;

    ion-badge {
      color: #fff;
      padding: 5px;
    }
    
    p {
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: gray;
    }
    
    span.rejection {
      color:red;
      font-weight:600;
      font-size: unset;
    }
    .new {
      background: #fffcf5;
    }
  }

  ion-slides {
    height: 100%;    
  }

  ion-button {
    height: 40px;
    font-family: "Play", sans-serif;
  }
  ion-searchbar {

    .searchbar-search-icon {
      width: 16px !important;
      left: 10px !important;
    }
    .searchbar-input.sc-ion-searchbar-md {
      color: #265066;
    }
  }
  .submit-btn {
    background: #efac14;
    --background: #efac14;
    color: #092430;
    font-weight: 600;
    border-radius: 100px;
    font-size: 14px;
    --border-radius: 100px;
  }
  .discharge-btn {
    background: #092430;
    --background: #092430;
    color: #efac14;
    font-size: 14px;
    margin-right: 5px;
    border-radius: 100px;
    --border-radius: 100px;
  }
}
ion-checkbox {
  margin-inline-end: 6px;
}
@supports (-webkit-touch-callout: none) {
  ion-header {
    padding-top: env(safe-area-inset-top);
  }
}
.selectHospitalModal ion-buttons ion-button {
  --color: #265066;
}
.mbeFooter {  
  height:84px;
  width:auto;
  line-height:44px;
  text-align: center;
  display: block;
  box-shadow: inset 0px 3px 5px #00000080;
  font-size:20px;
  --ion-toolbar-background: transparent;  
  color:#FFF;
  padding:20px;

  span.full {
    background-color:#efac14;
    background-image: linear-gradient(#efac14, #634400); 
    border-radius:10px;
    padding: 10px;
    height: 44px;
    line-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

ion-grid.mbe {
  padding: 20px 20px 0 20px;

  .msg-content {
    padding: 15px 20px;
    color: #265066;
    margin-bottom: 6px;
    h6 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 6px;
    }
    h5 {
      margin: 0;
      padding-bottom: 5px;
      color: #efac14;
      font-size: 18px;
      margin-bottom: 2px;
    }
    p {
      font-size: 14px;
      color: #959494;
      font-weight: 300;
    }
  }
}
ion-back-button.mbe {
  --padding-start: 0px;
  --padding-end: 20px;
}

.alert-button.cancel {
  background:#900202 !important;
  color:white !important;
  font-weight:normal !important;
}
.alert-button.okay {
  background:#0a760a !important;
  color:white !important;

}
ion-list ion-label {
  font-family: "Play", sans-serif;
  color:#265066;

}
.action-sheet-button {
  --button-color:#efac14;
  --button-background:#265066;
}
.action-sheet-cancel {
  --button-color:#265066;
  --button-background:#efac14;
  --button-background-selected:#efac14;
}
.action-sheet-selected {
  --button-color-selected:#265066;
  --button-background-selected:#efac14;
}

.help-btn {  
  display:flex;
  justify-content:center;
  align-items:center;
  margin:auto;
  padding-top:20px;
}

.markasread { 
  .alert-title {
    margin:0;
  }
  .alert-button-group.sc-ion-alert-md {
    justify-content: space-evenly;    
  }
  .alert-button.sc-ion-alert-md {
    margin:0;
    margin-inline: 0;
    width:80px;
  }
  .alert-button-inner.sc-ion-alert-md {
    justify-content: center;
  }
  .alert-message {
    padding:20px;
    padding-top:20px !important;
    padding-inline: 20px;
    color:#265055 !important;
    --ion-text-color:#265066 !important;
    background: #FFF !important;
  }
  .alert-button-role-confirm {
    background:green !important;
    color:white !important;
  }
  .alert-button-role-cancel {
    background:red !important;
    color:white !important;
  }
}

ion-alert.success_alert.confirmalert .alert-message {
  padding: 20px 10px;
  padding-top: 10px !important;
  padding-bottom:3px !important;
  h6 {
    font-size: 16px;
    padding-top: 10px;
    margin: 15px -10px -5px -10px;
    background: #dedede;
    color: #265066;
  }
}

.alert-wrapper {
  background: #dedede !important;

  .success-alert .alert-head {
    background: green;

  }
  .alert-head, .alert-message {
    background: #265066;
    color:white;
    --ion-text-color:white;
  }
  

  

  .alert-checkbox-label {
    color:#265066;
    font-weight:600;
  }
  .alert-button {
    background:white;
    color:#265066;
  }
}
.billing-select-modal {
  background:#FFF;
  --background:#FFF;
  --ion-item-background:#FFF;

  ion-item {
    height:50px;
    line-height:50px;
    color:#265066;
    font-weight:600;

    ion-label {
      margin:0;
    }
  }
}

.about_headings
{
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #265066;
    color: #265066;
    padding-bottom: 5px;
}



.badge ion-badge {
  position: absolute !important;
  right: 3px;
  font-size: 12px;
  background-color: #265066;
  padding: 3px;
  top: -4px;
  border: 1px solid #ddd;
  width: 20px;
  height: 20px;
  line-height:14px;
}
.badge {
  position: relative;
  top: 2px;
}

ion-row {
  ion-col {
    :focus-visible {
      outline: -webkit-focus-ring-color auto 0px !important;
    }
  }
}
ion-button {
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
}
ion-button.ion-activated {
  --background: transparent !important;
  background: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
}
.mbeAccordion.alert {
  background:rgb(173, 0, 0);
  color:white !important;
  ion-label {
    font-size:0.9em;
    color:white !important;
  }
  ion-icon {
    color:white;
  } 
}
.mbeAccordion.search{
    background: #265066;
    border-radius:6px;
}
.billing-modal {
    --backdrop-opacity:0.6;
  .popover-content {
    width: 86%;
    left: 7% !important;
    min-height: 50px;
    border-radius: 10px;
    --box-shadow: 0 0 18px rgba(0, 0, 0, 0.7);
  }
}
.billing-modal::part(content)
{
  --width:86%;
  left: 7%;
  min-height:50px;
  --box-shadow: 0 0 18px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.billing-modal::part(backdrop)
{
  background-color: #ffb100;
}
.menu-popover {
  .popover-content {
    --width: 180px;
    left:unset !important;
    right:10px;
  }
  .popover-arrow {
    left:unset !important;
    right:20px;
  }

}
.nofound {
  text-align: center;
  padding: 20px;
}
ion-alert.success_alert, ion-alert.fail_alert {
  --backdrop-opacity: 0.8;
  .alert-title {
    margin-top:0;
  }
  .alert-message {
    padding:20px 10px;
    padding-top:10px !important;
  }
}
ion-alert.success_alert {
  --background: green;
  .alert-head {
  
    background:green;
  }
  button{
      color: green !important;
  }
  button.red {
    color:red !important;
  }

  .alert-message {
    max-height: unset;
  }
  .basicbox {
    color:white;background-color:#29b34e; display: inline;  padding: 3px 5px; border-radius: 3px;
  }
  .advancedbox {
    color:white;background-color:#4277b8; display: inline;  padding: 3px 5px; border-radius: 3px;
  }
}
ion-alert.fail_alert {
  --background: red;
  .alert-head {
    background:red;
  }
  button{
      color: red !important;
  }
  button.action {
    color:white !important;
    background-color: red;
  }
}
.missingAlert .alert-head
{
  background-color:red;
  text-align: center;
}
.blueAlert .alert-head
{
  background-color:#092430;
  text-align: center;
}

.missingAlert .alert-message
{
  padding-top:10px !important;
}
.missingAlert.centreAlert .alert-message {
  text-align:center;
}

.missingAlert .alert-button-group 
{
  background-color:cadetblue;
}
.blueAlert .alert-button-group 
{
  background-color:#092430;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

ion-checkbox {
  --size: 20px;
  --border-radius: 5px;
}

.selectHospitalModal {
  .modal-wrapper {
    top: 50%;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    ion-header {
      ion-toolbar {
        --background: #fff;
        background: #fff;
        box-shadow: none !important;
        --box-shadow: none !important;
        ion-title {
          color: #265066;
          padding-left: 10px;
          font-weight: bold;
        }
        ion-buttons {
          ion-button {
            color: #265066;
          }
          ion-button:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.ionic-selectable-modal {
  ion-header ion-toolbar {
    --background: #ffffff !important;
    ion-button {
      color: #302e2e;
    }
  }
  ion-footer {
    ion-button {
      --background: #efac14 !important;
      border-radius: 100px !important;
    }
  }
}
.ionic-selectable {
  height: 40px;
  padding-left: 50px;
  span {
    display: flex;
    justify-content: flex-start;
    .ionic-selectable-value-item {
      margin-right: 10px;
    }
  }
}

ion-toast.toast-error {
  --background: #ff0000;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #FFFFFF;
  text-align:center;
}
.patientFooter, .patientToolbar {
  padding-top:5px;
  padding-bottom:5px;
  background:#092430;
  --background:#092430;
  text-align:center;
}
.ocrButton::part(native) {
    color:white;
    background-color: #efac14;
    background-image: linear-gradient(#efac14, #634400);
    
}
.ocrButton ion-icon {
  margin-right:5px;    
}
.patientOcrResults {
  margin:10px 0;
  text-align:justify;
}
.patientOcrFlex {
  display:flex;
  justify-content:space-between;
  border-bottom:1px solid #efac14;
  .left {
    padding-right:5px;
    width:50%;
    text-align:right;
  }
  .right {
    padding-left:5px;
    width:50%;
    text-align:left;
  }
}
.weblogo {
  height:47px;
  margin:5px 0 10px 10px;
}
.regapp {
    color:#4959c1;
    font-family: dm-sans-36pt;
    font-size:18px;
}
.welcome_title {
  display: block;
  font-family: dm-sans-36pt;
  font-size: calc(70vmin / 5);
	line-height: 1;
  background: -webkit-linear-gradient(273deg, #dcdeeb 0%, #4959c1 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.welcome_text {
  display: block;
  color:#7386ff;
}
@media (prefers-color-scheme: light) { 

  
  .mbeFooter, .mbeImageFooter {    
    background: #bedaef;
  }
  .notification-section ion-label {
    --ion-text-color: #FFF;
    color:#FFF !important;
  }
  .notification-section ion-icon {
    color:#efac14;
  }
  
  .mbe-titles {
    color:#265066  !important;
  }
  .form-content ion-item {
    --background: #ffffffb5;
  }
  .form-content ion-item.noline-item {
    background: transparent;
    --background: transparent;
  }
  .form-content ion-icon {
    background:transparent;
    color:#265066;
  }
  ion-header {
    .modal-header{
      background: #265066;
      color: #fff;
    }
  }
  ion-tab-bar {
    padding:8px 0;
    background:#efac14;
  }
  ion-tab-button {
    background:#efac14;
    color:#265066;
  }
  .patientAlert {
    .alert-head {
      background:red;
      padding-bottom:12px;
      h2 {
        margin-top:0;
      }
    }
    .alert-message {
      padding-top:20px;
    }
    .delButton {
      background:red;
      color:white;
    }
    
  }
  .help-btn {
    color: #265066;
  }
  .modal-background {
    background-image: linear-gradient(#e2f5ff, #96c9e6);
  }
  .speciality-form-content {  
    .patientFieldTitle  {
      background: #265066;
      --background: #265066;
      color:white;
    }
    ion-item.mbe {
      background: #ffffffb5;
      --background: #ffffffb5;

      ion-icon {
        color: #265066;
      }
    }
  }

  

  ion-content, .items-modal .modal-wrapper, .show-modal .modal-wrapper {
    --background: none;
    background: #FFF;
    background-image: url(/assets/img/lightbackground.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  ion-content.webhome, .webhome .items-modal .modal-wrapper, .webhome .show-modal .modal-wrapper {
    --background: none;
    background: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  ion-content.webhomegray, .webhomegray .items-modal .modal-wrapper, .webhomegray .show-modal .modal-wrapper {
    --background: none;
    background: #f3f2f8;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  
  
  ion-checkbox {
    --ion-color-base: #efac14 !important;
    --background-checked: #efac14;
    --border-color-checked: #df9e07;
    --checkmark-color:#092430;
    --background:#eaeaea;
  }
  
}

body.light { 
  .ion-color-mbe {
    --ion-color-base: var(--ion-color-mbe);
    --ion-color-base-rgb: var(--ion-color-mbe-rgb);
    --ion-color-contrast: var(--ion-color-mbe-contrast);
    --ion-color-contrast-rgb: var(--ion-color-mbe-contrast-rgb);
    --ion-color-shade: var(--ion-color-mbe-shade);
    --ion-color-tint: var(--ion-color-mbe-tint);
    --background: #ffffffb5;
    background:red;
  }
}
body.dark { 
  .datetime-header {
    background:black;
  }
  --ion-color-medium: var(--ion-color-mbedark);
  .ion-color-mbe {
    --ion-color-base: #FFF;
    --title-color: #FFF;
    --background: #ffffff2e;
  }
}



ion-accordion.searchAccordion > [slot=header] .ion-accordion-toggle-icon {
  color:white;
}

ion-accordion.accordion-expanding > [slot=header] .ion-accordion-toggle-icon, 
ion-accordion.accordion-animated > [slot=header] .ion-accordion-toggle-icon {
  position:unset;
  padding:0;
}
.mbeaccordionlabel {  
  padding-left:50px;
  font-size:14px !important;
}
.mbedatepicker {
  border:1px solid #265066;
  border-top:0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom:10px;
}
@media (prefers-color-scheme: dark) { 

  .dark-mode {

  

  .upload-btn {
    --background: #efac14 !important;
    color:#265066 !important;
  }
  .upload-icon {
    color:#FFFFFF !important;
  }
  .about_headings
  {
      font-size: 1.2em;
      font-weight: 400;
      text-align: center;
      border-bottom: 1px solid #efac14;
      color: #FFF;
      padding-bottom: 5px;
  }

  .billing-rows {
    display:flex;
    width:100%;  
    padding:5px 0;
    align-items:center;

    ion-label {
      flex:1;
      margin-bottom:0 !important;
      color:#FFF;
    }
  }
  .mbeFooter {    
    background: #265066;
  }
  

  ion-checkbox {
    --ion-color-base: #efac14 !important;
    --background-checked: #efac14;
    --border-color-checked: #df9e07;
    --checkmark-color:#092430;
    --background:#265066;
  }

  .speciality-form-content {  
    .patientFieldTitle  {
      background: #092430;
      --background: #092430;
    }
    ion-item.mbe {
      background: #09243075;
      --background: #09243075;
      
      ion-icon {
        color: #fff;
      }
    }
  }
  .modal-background {
    background-image: linear-gradient(#21475a, #0e1e27);
  }
  ion-header {
    .modal-header{
      background: #092430;
      color: #fff;
    }
  }
  .help-btn {
    color: #FFF;  
  }

  .mbe-titles {
    color:#FFFFFF !important;
  }
  ion-picker {
  .picker-toolbar {
    --background: #efac14 !important;
    background: #efac14 !important;
    border:none;

      .picker-toolbar-button.sc-ion-picker-ios:first-child {
          border-right:1px solid #265066;
      }
  }
  .picker-above-highlight {
    background: linear-gradient(#092430, #936600b8) !important;
  }
  .picker-below-highlight {
    background: linear-gradient(#936600b8, #092430) !important;
  }

    .picker-wrapper {
      border:none;
    }

    .picker-button {
      color:#265066 !important;
    }
    .picker-columns {
      background:#092430;
      color: #efac14;
    }
    .picker-opt {
      height:42px;
      line-height:42px;
    }

  }
  .item, ion-label {
    color:#FFFFFF;
    --color: #FFFFFF;
  }  
  .app-settings-menu ion-item {
    color:#265066;
    --color:#265066;
  }
  ion-textarea, ion-input, ion-select {
    color:#FFFFFF !important;
  }
  .loginform ion-input {    
    color:#265066 !important;
  }
  ion-list {
    --ion-background-color: #efac14;
    --background:#efac14;
    --color:#000;
    ion-item {
      color:#265266;
      font-weight: 600;
    }
    ion-label {
    font-family: "Play", sans-serif;
    color:#265066;    
    }
  }
  .billing-select-modal ion-item {
    color:#265066;
  }
  ion-list.skelton-list,  .notification-skelton, .billing-skelton, .message-skelton {
    --ion-background-color: #092430;
    --background:#092430;

  }
  .help-info {
    color:#265066;
  }
  .popover-arrow {
    --background:#efac14;
  }
  .item-lines-full {    
    --ion-border-color: #265266;
  }
  ion-content, .items-modal .modal-wrapper {
    --background: none;
    background: #000;
    
  --color:#FFFFFF;
    background-image: url(/assets/img/darkbackground.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  
  
  ion-content {    

    .logo {
      content: url("/assets/img/mbe-light.svg");
    } 

    .error-message {
      color:#f69a9a;
      position:relative;
      top:-12px;
    }

    .bg-section {
      --background: #092430;
      text-align: center;
      margin: 10px;
      border-radius: 3px;
      ion-icon {
        color: #a2d3e9;
        font-size: 38px;
        margin-top: 20px;
      }
      h4 {
        color: #a2d3e9;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 5px;
      }
      h3 {
        color: #a2d3e9;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        margin-top: 5px;
      }
    }

    .notification-section {
      box-shadow: 0px 2px 6px #000000b5;
      padding: 15px;
      margin-bottom: 20px;
      background: #092430;
  
      ion-badge {
        color: #fff;
        padding: 5px;
      }
      
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
        color: gray;
      }
      .new {
        background: #fffcf5;
      }
    }
  }
  div.addmode {
    background: #0000007d;
  }
  .form-content {
    background:transparent;
    --background:transparent;

    ion-item.addmode, div.addmode {
      border: 0;     
      --background: #0000007d;
    }
    ion-item {
      border: 0;
     
      --background: #00000033;

      ion-icon {
        color:white;
        background: transparent;
      }
    }    
  }
  ion-grid.mbe {
    padding: 70px 20px 0 20px;
  
    .msg-content {
      padding: 15px 20px;
      color: #FFF;
      margin-bottom: 6px;
      h6 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 6px;
      }
      h5 {
        margin: 0;
        padding-bottom: 5px;
        color: #efac14;
        font-size: 18px;
        margin-bottom: 2px;
      }
      p {
        font-size: 14px;
        color: #959494;
        font-weight: 300;
      }
    }
  }
  ion-back-button.mbe {
    --padding-start: 0px;
    --padding-end: 20px;
  }
}
}

body.dark {
  .upload-btn {
    --background: #efac14 !important;
    color:#265066 !important;
  }
  .upload-icon {
    color:#FFFFFF !important;
  }
  .about_headings
  {
      font-size: 1.2em;
      font-weight: 400;
      text-align: center;
      border-bottom: 1px solid #efac14;
      color: #FFF;
      padding-bottom: 5px;
  }

  .billing-rows {
    display:flex;
    width:100%;  
    padding:5px 0;
    align-items:center;

    ion-label {
      flex:1;
      margin-bottom:0 !important;
      color:#FFF;
    }
  }
  .mbeFooter, .mbeImageFooter {    
    background: #265066;
  }
  
  

  ion-checkbox {
    --ion-color-base: #efac14 !important;
    --background-checked: #efac14;
    --border-color-checked: #df9e07;
    --checkmark-color:#092430;
    --background:#265066;
  }

  .speciality-form-content {  
    .patientFieldTitle  {
      background: #092430;
      --background: #092430;
    }
    ion-item.mbe {
      background: #09243075;
      --background: #09243075;
      
      ion-icon {
        color: #fff;
      }
    }
  }
  .modal-background {
    background-image: linear-gradient(#21475a, #0e1e27);
  }
  ion-header {
    .modal-header{
      background: #092430;
      color: #fff;
    }
  }
  .help-btn {
    color: #FFF;  
  }

  .mbe-titles {
    color:#FFF;
  }
  ion-picker {
  .picker-toolbar {
    --background: #efac14 !important;
    background: #efac14 !important;
    border:none;

      .picker-toolbar-button.sc-ion-picker-ios:first-child {
          border-right:1px solid #265066;
      }
  }
  .picker-above-highlight {
    background: linear-gradient(#092430, #936600b8) !important;
  }
  .picker-below-highlight {
    background: linear-gradient(#936600b8, #092430) !important;
  }

    .picker-wrapper {
      border:none;
    }

    .picker-button {
      color:#265066 !important;
    }
    .picker-columns {
      background:#092430;
      color: #efac14;
    }
    .picker-opt {
      height:42px;
      line-height:42px;
    }

  }
  .item, ion-label {
    color:#FFFFFF;
    --color: #FFFFFF;
  }
  .app-settings-menu ion-item {
    color:#265066;
    --color:#265066;
  }
  ion-textarea, ion-input, ion-select {
    color:#FFFFFF !important;
  }  
  .loginform ion-input {    
    color:#265066 !important;
  }
  ion-list {
    --ion-background-color: #efac14;
    --background:#efac14;
    --color:#000;
    ion-item {
      color:#265266;
      font-weight: 600;
    }
    ion-label {
      font-family: "Play", sans-serif;
      color:#265066;    
    }
  }
  .billing-select-modal ion-item {
    color:#265066;
  }
  ion-list.skelton-list, .notification-skelton, .billing-skelton, .message-skelton {
    --ion-background-color: #092430;
    --background:#092430;

  }
  .help-info {
    color:#265066;
  }
  .popover-arrow {
    --background:#efac14;
  }
  .item-lines-full {    
    --ion-border-color: #265266;
  }
  ion-content, .items-modal .modal-wrapper {
    --background: none;
    background: #000;
    --color:#FFFFFF;
    background-image: url(/assets/img/darkbackground.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  
  ion-content {    

    .logo {
      content: url("/assets/img/mbe-light.svg");
    } 

    .error-message {
      color:#f69a9a;
      position:relative;
      top:-12px;
    }

    .bg-section {
      --background: #092430;
      text-align: center;
      margin: 10px;
      border-radius: 3px;
      ion-icon {
        color: #a2d3e9;
        font-size: 38px;
        margin-top: 20px;
      }
      h4 {
        color: #a2d3e9;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 5px;
      }
      h3 {
        color: #a2d3e9;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        margin-top: 5px;
      }
    }

    .notification-section {
      box-shadow: 0px 2px 6px #000000b5;
      padding: 15px;
      margin-bottom: 20px;
      background: #092430;
  
      ion-badge {
        color: #fff;
        padding: 5px;
      }
      
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
        color: gray;
      }
      .new {
        background: #fffcf5;
      }
    }
  }
  div.addmode {
    background: #0000007d;
  }
  .form-content {
    background:transparent;
    --background:transparent;

    ion-item.addmode, div.addmode {
      border: 0;     
      --background: #0000007d;
    }
    ion-item {
      border: 0;
     
      --background: #00000033;

      ion-icon {
        color:white;
        background: transparent;
      }
    }    
  }
  ion-grid.mbe {
    padding: 70px 20px 0 20px;
  
    .msg-content {
      padding: 15px 20px;
      color: #FFF;
      margin-bottom: 6px;
      h6 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 6px;
      }
      h5 {
        margin: 0;
        padding-bottom: 5px;
        color: #efac14;
        font-size: 18px;
        margin-bottom: 2px;
      }
      p {
        font-size: 14px;
        color: #959494;
        font-weight: 300;
      }
    }
  }
  ion-back-button.mbe {
    --padding-start: 0px;
    --padding-end: 20px;
  }
}