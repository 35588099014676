// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #128440;
  --ion-color-success-rgb: 18, 132, 64;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #128440;
  --ion-color-success-tint: #11803d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


    /** mbe **/
    --ion-color-mbe: #265066;
    --ion-color-mbe-rgb: 38,80,102;
    --ion-color-mbe-contrast: #ffffff;
    --ion-color-mbe-contrast-rgb: 255,255,255;
    --ion-color-mbe-shade: #21465a;
    --ion-color-mbe-tint: #3c6275;

    
    /** mbedark **/
    --ion-color-mbedark: #092430;
    --ion-color-mbedark-rgb: 9,36,48;
    --ion-color-mbedark-contrast: #ffffff;
    --ion-color-mbedark-contrast-rgb: 255,255,255;
    --ion-color-mbedark-shade: #08202a;
    --ion-color-mbedark-tint: #223a45;
  
    /** webfooter **/
    --ion-color-webfooter: #FFF;
    --ion-color-base: #15112a;
}


.ion-color-mbe {
  --ion-color-base: var(--ion-color-mbe);
  --ion-color-base-rgb: var(--ion-color-mbe-rgb);
  --ion-color-contrast: var(--ion-color-mbe-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mbe-contrast-rgb);
  --ion-color-shade: var(--ion-color-mbe-shade);
  --ion-color-tint: var(--ion-color-mbe-tint);
  --background: #ffffffb5;
}

.red{
  background-color: #ff6968;
}
.blue{
  background-color: #7a54ff;
}
.orange{
  background-color: #ff8f61;
}
.sky{
  background-color: #2cc2fd;
}
.light-blue{
  background-color: #5a65ff;
}
.light-blue{
  background-color: #5a65ff;
}
.green{
  background-color: #96da45;
}